import React from "react"
import { Box } from "rebass"

const before = "{ value: 3 }"
const after = '{ value: { N: "3" } }'

const JSON = () => (
  <Box mt={[32, 62]}>
    <h2>DynamoDB JSON Format - Here's What You Need to Know</h2>
    <p>
      DynamoDB does <b>not</b> use the classical JSON format to store items
      internally. Instead, it uses a <i>"marshalled"</i> format. DynamoDB
      wraps all the attribute values in objects where the Key indicates its type
      and attribute value stays as-is. For instance: <code>{before}</code>{" "}
      becomes <code>{after}</code>.
    </p>
  </Box>
)

export default JSON
