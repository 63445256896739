import React, { useState } from "react"
import { Box, Flex } from "rebass"
import { Textarea } from "@rebass/forms"
import * as converter from "dynamo-converters"

const Tool = () => {
  const [input, setInput] = useState("")
  const [marshalled, setMarshalled] = useState("")

  const onChange = str => {
    setInput(str)

    try {
      const json = JSON.parse(str)
      const marsh = converter.dataToItem(json)
      delete marsh["created"]
      delete marsh["modified"]
      setMarshalled(JSON.stringify(marsh, null, 2))
      return
    } catch (error) {
      console.warn("Failed to parse input as JSON", error)
    }

    try {
      const evaluated = eval(`(${str})`)
      console.log(evaluated)
      const marsh = converter.dataToItem(evaluated)
      delete marsh["created"]
      delete marsh["modified"]
      setMarshalled(JSON.stringify(marsh, null, 2))
    } catch (error) {
      console.warn("Failed to parse input as JSON", error)
    }
  }

  const onChangeMarshalled = str => {
    try {
      const data = converter.itemToData(JSON.parse(str))
      setMarshalled(str)
      setInput(JSON.stringify(data, null, 2))
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Box mt={[32, 62]}>
      <Flex height="260px">
        <Textarea
          placeholder="Paste JSON/JS Object here"
          value={input}
          onChange={e => onChange(e.target.value)}
        />
        <Textarea
          placeholder="DynamoDB JSON will appear here"
          value={marshalled}
          onChange={e => onChangeMarshalled(e.target.value)}
        />
      </Flex>
    </Box>
  )
}

export default Tool
