import React from "react"
import { Box } from "rebass"

const Intro = () => (
  <Box mt={62}>
    <h1 style={{ letterSpacing: -1, fontSize: "32px" }}>
      DynamoDB Converter Tool
    </h1>
    <p style={{ marginBottom: "0.8em" }}>
      This tool helps you convert plain JSON or JS object into a
      DynamoDB-compatible JSON format.
    </p>
  </Box>
)

export default Intro
