import React from "react"
import { FooterWithCTA, SEO, Layout } from "@components"
import Intro from "@components/pages/converter-tool/Intro"
import Tool from "@components/pages/converter-tool/Tool"
import JSON from "@components/pages/converter-tool/JSON"
import Resources from "@components/pages/converter-tool/Resources"
import { Container } from "@components"

const ConverterTool = () => {
  return (
    <>
      <Layout>
        <SEO
          title="Convert JSON to DynamoDB JSON (&amp; back) [Free Tool]"
          description="This free tool helps you convert plain JS Objects and JSON to DynamoDB compatible JSON format and back."
          canonical="https://dynobase.dev/dynamodb-json-converter-tool/"
        />
        <div>
          <Container>
            <Intro />
            <Tool />
            <JSON />
            <Resources />
          </Container>
          <FooterWithCTA />
        </div>
      </Layout>
    </>
  )
}

export default ConverterTool
