import React from "react"
import { feature } from "@constants/urls.js"
import { InternalLink } from "@fragments"
import { Box } from "rebass"

const RelatedResources = () => (
  <Box mt={[32, 62]}>
    <h2>Related resources</h2>
    <ul>
      <li>
        <InternalLink to={feature.importCSV}>
          Import CSV to DynamoDB
        </InternalLink>
      </li>
      <li>
        <InternalLink to={feature.importJSON}>
          Import JSON to DynamoDB
        </InternalLink>
      </li>
      <li>
        <InternalLink to={feature.exportCSV}>
          Export DynamoDB to CSV
        </InternalLink>
      </li>
      <li>
        <InternalLink to={feature.exportJSON}>
          Export DynamoDB to JSON
        </InternalLink>
      </li>
      <li>
        <InternalLink to={feature.exportToS3}>
          Export DynamoDB to S3
        </InternalLink>
      </li>
    </ul>
  </Box>
)

export default RelatedResources
